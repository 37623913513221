import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'

export function DocumentNameRender(data: any) {
  return (
    <div>
      {data?.data?.status === 'Active' || data?.data?.status === 'Lapsed' ? (
        <span>{data.data.termsheetName}</span>
      ) : (
        <Link
          className='fw-bold text-gray-900 text-hover-primary'
          to={'/deals/draftTermsheet'}
          state={data.data}
        >
          {data.data.termsheetName}
        </Link>
      )}
    </div>
  )
}
export function SentStatusRender(data: any) {
  return (
    <div className='px-4'>
     {data.data.sentStatus === "Yes" ? (
      <KTSVG
        path='/media/icons/duotune/general/gen043.svg'
        className='ps-md-2 svg-icon-2x align-bottom text-primary align-text-top'
      />
     ) : <p className='px-4 m-0'>-</p>}
    </div>
  )
}