import { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../_metronic/helpers'
import {
  GetUrlForS3ObjectQuery,
  SendTermsheetToCaptableMutation,
  SendTermsheetToCaptableMutationVariables,
  useGetTermsheetsQuery,
  useGetUrlForS3ObjectQuery,
  useSendTermsheetToCaptableMutation,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import { downloadS3File } from '../../../../../utils/ExportExcel'
import LazyPdfDocument from '../../../../../utils/LazyPdfDocument'
import Toast, { ToastType } from '../../../../../utils/toast'
import EmailTermsheet from '../../../open-deals/components/emailTermsheet/EmailTermsheet'
import SignAndUploadModal from '../signature-flow/SignAndUploadModal'
import DealsDocumentEditor from './DealsDocumentEditor'

type Props = {
  showModal?: boolean
  handleClose: () => void
  termsheetData: any
}
type ActionMenu = {
  label: string
  state: boolean
}

export function DealsActionRender(data: any) {
  const navigate = useNavigate()
  const [showShareModal, setShowShareModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showRegenerateModal, setShowRegenerateModal] = useState(false)
  const [showViewDocModal, setShowViewDocModal] = useState(false)
  const [showSignModalPopup, setShowSignModalPopup] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [data?.data])

  const handleActionButtonClick = (label: string) => {
    if (label === 'Quick View') {
      setShowViewDocModal(true)
    } else if (label === 'Send to Company') {
      sendTermsheetToCaptableMutation.mutate({
        input: {
          termsheetId: Number(data.data.id),
        },
      })
    } else if (label === 'Share Document') {
      setShowShareModal(true)
    } else if (label === 'Edit Document') {
      setShowEditModal(true)
    } else if (label === 'Re-generate Document') {
      setShowRegenerateModal(true)
    } else if (label === 'Edit Form') {
      navigate('/deals/draftTermsheet', { state: data?.data })
    } else if (label === 'Sign and Upload') {
      setShowSignModalPopup(true)
    }
  }
  const editOptions: ActionMenu[] = [
    { label: 'Quick View', state: false },
    { label: 'Edit Form', state: false },
    // { label: 'Edit Document', state: false },
    { label: 'Re-generate Document', state: false },
    { label: 'Send to Company', state: false },
    { label: 'Share Document', state: false },
    { label: 'Sign and Upload', state: false },
  ]
  if (
    data.data &&
    data.data.status === 'Active' &&
    data.data.isSentToCompany === false &&
    data.data.termsheetType?.toLowerCase() === 'termsheet'
  ) {
    editOptions.forEach((option) => {
      if (option.label === 'Send to Company') {
        option.state = true
      }
    })
  }
  if (data?.data?.fileURL !== '') {
    editOptions.forEach((option) => {
      if (['Quick View', 'Share Document'].includes(option.label)) {
        option.state = true
      }
    })
  }
  if (data?.data?.isSigned === false) {
    if (data?.data?.uploadedPdfURL === '') {
      editOptions.forEach((option) => {
        if (['Edit Document', 'Re-generate Document'].includes(option.label)) {
          option.state = true
        }
      })
    }
  }
  if (data?.data?.uploadedPdfURL === '') {
    editOptions.forEach((option) => {
      if (option.label === 'Edit Form') {
        option.state = true
      }
    })
  }
  if (
    data?.data?.signedPdfURL === '' &&
    data?.data?.isSigned === false 
  ) {
    editOptions.forEach((option) => {
      if (option.label === 'Sign and Upload') {
        option.state = true
      }
    })
  }
  const actionMenu = () => {
    let newOptions = editOptions
    return newOptions.map((option) => {
      return (
        <label
          className={`form-check-custom py-3 ${
            option.state
              ? 'bg-hover-primary text-hover-white'
              : 'bg-hover-secondary text-hover-gray-600 '
          }`}
          style={{cursor:`${option.state? 'pointer':'not-allowed'}`}}
          onClick={() => {
            if (option.state === true) handleActionButtonClick(option.label)
          }}
        >
          <span className='form-check-label fs-base ms-6 fw-normal'>
            {option.label}
          </span>
        </label>
      )
    })
  }

  const sendTermsheetToCaptableMutation = useSendTermsheetToCaptableMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SendTermsheetToCaptableMutation,
        _variables: SendTermsheetToCaptableMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        Toast('Termsheet has been successfully sent to the company', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  return (
    <div className='px-0'>
      <button
        type='button'
        className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen052.svg'
          className='svg-icon-muted svg-icon-2x'
        />
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
        data-kt-menu='true'
      >
        {actionMenu()}
        <EmailTermsheet
          showModal={showShareModal}
          handleClose={() => setShowShareModal(false)}
          termsheetId={data?.data?.id}
          type={data?.data?.type}
        />
        {showEditModal && (
          <DealsDocumentEditor
            termsheetData={data.data}
            showModel={showEditModal}
            handleClose={() => {
              setShowEditModal(false)
            }}
          />
        )}
        {showRegenerateModal && (
          <RegeneratePopup
            showModal={showRegenerateModal}
            handleClose={() => {
              setShowRegenerateModal(false)
            }}
            termsheetData={data.data}
          />
        )}
        {showViewDocModal && (
          <QuickViewPdf
            showModal={showViewDocModal}
            handleClose={() => {
              setShowViewDocModal(false)
            }}
            termsheetData={data.data}
          />
        )}
        {showSignModalPopup && (
          <SignAndUploadModal
            showDialog={showSignModalPopup}
            documentData={data.data}
            handleClose={() => {
              setShowSignModalPopup(!showSignModalPopup)
            }}
          />
        )}
      </div>
    </div>
  )
}

function RegeneratePopup({ handleClose, showModal, termsheetData }: Props) {
  const navigate = useNavigate()
  return (
    <Modal
      dialogClassName='modal-dialog modal-dialog-centered mw-700px '
      show={showModal}
      onHide={() => {
        handleClose()
      }}
    >
      <ModalHeader>
        <ModalTitle>
          <h1 className='text-warning'>Warning</h1>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <>
          <div>
            <h3>
              If you continue, the existing document will be replaced with the re-generated document
            </h3>
          </div>
          <div>
            <button
              className='btn btn-secondary mt-5'
              onClick={() => {
                handleClose()
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary mt-5 float-end'
              onClick={() => {
                navigate('/deals/draftTermsheet', { state: termsheetData })
              }}
            >
              Continue
            </button>
          </div>
        </>
      </ModalBody>
    </Modal>
  )
}

function QuickViewPdf({ handleClose, showModal, termsheetData }: Props) {
  const DocumentLink = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        key: termsheetData?.isSigned
          ? termsheetData?.signedPdfURL
          : termsheetData?.uploadedPdfURL === ''
          ? termsheetData?.fileURL
          : termsheetData?.uploadedPdfURL,
        bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
      },
    },
    {}
  )
  return (
    <Modal
      id='kt_header_search_modal'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered h-auto mw-800px'
      show={showModal}
      centered
      animation={true}
      backdrop={true}
      onHide={() => {
        handleClose()
      }}
    >
      <Modal.Header className='px-9'>
        <Modal.Title className='fs-1 text-gray-heading'>Document Pdf View</Modal.Title>
        <div className='justify-content-end'>
          <button
            className='btn btn-sm btn-primary me-2'
            type='button'
            data-bs-toggle='tooltip'
            title='Download'
            onClick={() => {
              downloadS3File(DocumentLink.data?.urlForS3Object || '')
            }}
          >
            Download
            <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2 mx-1' />
          </button>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => handleClose()}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className='px-15 overflow-auto h-800px'>
        <div className='fv-row mb-4 justify'>
          <LazyPdfDocument url={DocumentLink.data?.urlForS3Object || ''} />
        </div>
      </Modal.Body>
    </Modal>
  )
}